.tooltip {
    z-index: 10000000;
    // z-index: 1070;
    display: block;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-style: normal;
    font-weight: normal;
    letter-spacing: normal;
    line-break: auto;
    line-height: 1.42857143;
    text-align: left;
    text-align: start;
    text-decoration: none;
    text-shadow: none;
    text-transform: none;
    white-space: normal;
    word-break: normal;
    word-spacing: normal;
    word-wrap: normal;
    font-size: 12px;
    opacity: 0;
    filter: alpha(opacity=0);
  }
  .tooltip.nowrap .tooltip-inner {
    white-space: nowrap;
  }
  .tooltip.in {
    opacity: 0.9;
    filter: alpha(opacity=90);
  }
  .tooltip.top {
    margin-top: -3px;
    padding: 5px 0;
  }
  .tooltip.right {
    margin-left: 3px;
    padding: 0 5px;
  }
  .tooltip.bottom {
    margin-top: 3px;
    padding: 5px 0;
  }
  .tooltip.left {
    margin-left: -3px;
    padding: 0 5px;
  }
  .tooltip-inner {
    max-width: 1000px;
    padding: 3px 8px;
    color: #fff;
    text-align: center;
    background-color: rgba(0, 0, 0, 0.8);
    border-radius: 4px;
  }
  .tooltip-arrow {
    position: absolute;
    width: 0;
    height: 0;
    border-color: transparent;
    border-style: solid;
  }
  .tooltip.top .tooltip-arrow {
    bottom: 0;
    left: 50%;
    margin-left: -5px;
    border-width: 5px 5px 0;
    border-top-color: rgba(0, 0, 0, 0.8);
  }
  .tooltip.top-left .tooltip-arrow {
    bottom: 0;
    right: 5px;
    margin-bottom: -5px;
    border-width: 5px 5px 0;
    border-top-color: rgba(0, 0, 0, 0.8);
  }
  .tooltip.top-right .tooltip-arrow {
    bottom: 0;
    left: 5px;
    margin-bottom: -5px;
    border-width: 5px 5px 0;
    border-top-color: rgba(0, 0, 0, 0.8);
  }
  .tooltip.right .tooltip-arrow {
    top: 50%;
    left: 0;
    margin-top: -5px;
    border-width: 5px 5px 5px 0;
    border-right-color: rgba(0, 0, 0, 0.8);
  }
  .tooltip.left .tooltip-arrow {
    top: 50%;
    right: 0;
    margin-top: -5px;
    border-width: 5px 0 5px 5px;
    border-left-color: rgba(0, 0, 0, 0.8);
  }
  .tooltip.bottom .tooltip-arrow {
    top: 0;
    left: 50%;
    margin-left: -5px;
    border-width: 0 5px 5px;
    border-bottom-color: rgba(0, 0, 0, 0.8);
  }
  .tooltip.bottom-left .tooltip-arrow {
    top: 0;
    right: 5px;
    margin-top: -5px;
    border-width: 0 5px 5px;
    border-bottom-color: rgba(0, 0, 0, 0.8);
  }
  .tooltip.bottom-right .tooltip-arrow {
    top: 0;
    left: 5px;
    margin-top: -5px;
    border-width: 0 5px 5px;
    border-bottom-color: rgba(0, 0, 0, 0.8);
  }
